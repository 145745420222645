$medium: 960px;

* {
  box-sizing: border-box;
}

*:focus,
*.focus {
  outline: 0 transparent !important;
}

/* MAIN STRUCT */
html {
  height: 100%;
  font-size: 16px;
}

body {
  min-height: 100%;
  height: 100%;
  position: relative;
  margin: 0;
  overflow-x: clip;
  font-family: "Montserrat", sans-serif;
}

#root {
  @extend body;
  height: auto;
  display: flex;

  @media screen and (max-width: $medium) {
    flex-direction: column;
  }
}

.MuiInput-underline.Mui-disabled:before {
  border-bottom-style: solid !important;
}

// Scrollbar
.scrollhost::-o-scrollbar,
.scrollhost::-ms-scrollbar,
.scrollhost::-moz-scrollbar,
.scrollhost::-webkit-scrollbar {
  display: none;
}

.scrollhost-container {
  position: relative;
  height: 100%;
}

.scrollhost {
  overflow: auto;
  height: 100%;
  scrollbar-width: none;
  -ms-overflow-style: none;
  position: relative;
}

.scroll-bar {
  width: 10px;
  height: 100%;
  right: 0;
  top: 0px;
  position: absolute;
  border-radius: 7px;
  bottom: 0px;
  background-color: rgba(0, 0, 0, 0.35);
}

.scroll-thumb {
  width: 8px;
  height: 20px;
  margin-left: 1px;
  position: absolute;
  border-radius: 7px;
  opacity: 1;
  top: 0;
  background-color: rgba(0, 0, 0, 0.55);
}

// Scrollbar

/* width */
::-webkit-scrollbar {
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #9e9e9e94;
  border-radius: 20px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #737171;
}

.MuiAutocomplete-popper {
  border-radius: 14px;
  box-shadow: 0px 3px 6px #00000029;
}

// TIP NEW NOTIFY
.tip-new-notify-i {
  /*   positioning */
  position: absolute;
  opacity: 0;
  top: 0;
  left: 0;

  /*   gradient   */
  background: -moz-linear-gradient(left,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0.03) 1%,
      rgba(255, 255, 255, 0.6) 30%,
      rgba(255, 255, 255, 0.85) 50%,
      rgba(255, 255, 255, 0.85) 70%,
      rgba(255, 255, 255, 0.85) 71%,
      rgb(255, 255, 255) 100%);
  /* FF3.6+ */
  background: -webkit-gradient(linear,
      left top,
      right top,
      color-stop(0%, rgba(255, 255, 255, 0)),
      color-stop(1%, rgba(255, 255, 255, 0.03)),
      color-stop(30%, rgba(255, 255, 255, 0.85)),
      color-stop(50%, rgba(255, 255, 255, 0.85)),
      color-stop(70%, rgba(255, 255, 255, 0.85)),
      color-stop(71%, rgba(255, 255, 255, 0.85)),
      color-stop(100%, rgb(255, 255, 255)));
  /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(left,
      rgba(255, 0, 0, 0.048) 0%,
      rgba(255, 255, 255, 0.03) 1%,
      rgba(255, 255, 255, 0.6) 30%,
      rgba(255, 255, 255, 0.85) 50%,
      rgba(255, 255, 255, 0.85) 70%,
      rgba(255, 255, 255, 0.85) 71%,
      rgb(255, 255, 255) 100%);
  /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(left,
      rgba(228, 0, 0, 0.068) 0%,
      rgba(255, 255, 255, 0.03) 1%,
      rgba(255, 255, 255, 0.6) 30%,
      rgba(255, 255, 255, 0.85) 50%,
      rgba(255, 255, 255, 0.85) 70%,
      rgba(255, 255, 255, 0.85) 71%,
      rgb(255, 255, 255) 100%);
  /* Opera 11.10+ */
  background: -ms-linear-gradient(left,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0.03) 1%,
      rgba(255, 255, 255, 0.6) 30%,
      rgba(255, 255, 255, 0.85) 50%,
      rgba(255, 255, 255, 0.85) 70%,
      rgba(255, 255, 255, 0.85) 71%,
      rgba(255, 255, 255, 0.041) 100%);
  /* IE10+ */
  background: linear-gradient(to right,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0.03) 1%,
      rgba(255, 255, 255, 0.6) 30%,
      rgba(255, 255, 255, 0.85) 50%,
      rgba(255, 255, 255, 0.85) 70%,
      rgba(255, 255, 255, 0.85) 71%,
      rgb(255, 255, 255) 100%);
  /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00ffffff', endColorstr='#00ffffff', GradientType=1);
  /* IE6-9 */

  /*  forming the shine element
      play around with the width, skew and gradient to get different effects
    */
  width: 15%;
  height: 100%;
  transform: skew(-10deg, 0deg);
  -webkit-transform: skew(-10deg, 0deg);
  -moz-transform: skew(-10deg, 0deg);
  -ms-transform: skew(-10deg, 0deg);
  -o-transform: skew(-10deg, 0deg);

  /*  animating it  */
  animation: move 1.4s;
  animation-iteration-count: infinite;
  animation-delay: 1s;
  -webkit-animation: move 1.4s;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-delay: 1s;
  -moz-transform: skew(-10deg, 0deg);
  -moz-animation: move 1.4s;
  -moz-animation-iteration-count: infinite;
  -moz-animation-delay: 1s;
  -ms-transform: skew(-10deg, 0deg);
  -ms-animation: move 1.4s;
  -ms-animation-iteration-count: infinite;
  -ms-animation-delay: 1s;
  -o-transform: skew(-10deg, 0deg);
  -o-animation: move 1.4s;
  -o-animation-iteration-count: infinite;
  -o-animation-delay: 1s;
}

/*  */
@keyframes move {
  0% {
    left: 0;
    opacity: 0;
  }

  5% {
    opacity: 0;
  }

  48% {
    opacity: 0.2;
  }

  80% {
    opacity: 0;
  }

  100% {
    left: 82%;
  }
}

@-webkit-keyframes move {
  0% {
    left: 0;
    opacity: 0;
  }

  5% {
    opacity: 0;
  }

  48% {
    opacity: 0.2;
  }

  80% {
    opacity: 0;
  }

  100% {
    left: 82%;
  }
}

@-moz-keyframes move {
  0% {
    left: 0;
    opacity: 0;
  }

  5% {
    opacity: 0;
  }

  48% {
    opacity: 0.2;
  }

  80% {
    opacity: 0;
  }

  100% {
    left: 88%;
  }
}

@-ms-keyframes move {
  0% {
    left: 0;
    opacity: 0;
  }

  5% {
    opacity: 0;
  }

  48% {
    opacity: 0.2;
  }

  80% {
    opacity: 0;
  }

  100% {
    left: 82%;
  }
}

@-o-keyframes move {
  0% {
    left: 0;
    opacity: 0;
  }

  5% {
    opacity: 0;
  }

  48% {
    opacity: 0.2;
  }

  80% {
    opacity: 0;
  }

  100% {
    left: 82%;
  }
}

@keyframes fadein {
  0% {
    opacity: 0;
  }

  66% {
    opacity: 0.8;
  }

  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fadein {
  0% {
    opacity: 0;
  }

  66% {
    opacity: 0.8;
  }

  100% {
    opacity: 1;
  }
}

/*  */
@keyframes fadein {
  0% {
    opacity: 0;
  }

  66% {
    opacity: 0.8;
  }

  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fadein {
  0% {
    opacity: 0;
  }

  66% {
    opacity: 0.8;
  }

  100% {
    opacity: 1;
  }
}

@-moz-keyframes fadein {
  0% {
    opacity: 0;
  }

  66% {
    opacity: 0.8;
  }

  100% {
    opacity: 1;
  }
}

@-ms-keyframes fadein {
  0% {
    opacity: 0;
  }

  66% {
    opacity: 0.8;
  }

  100% {
    opacity: 1;
  }
}

@-o-keyframes fadein {
  0% {
    opacity: 0;
  }

  66% {
    opacity: 0.8;
  }

  100% {
    opacity: 1;
  }
}